
import { ref, onMounted, defineComponent, computed } from 'vue'
import { Modal } from 'ant-design-vue'
import { useStore } from 'vuex'
import * as actions from '../../../store/actions.type'
import {
  SyncOutlined,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  UserOutlined,
} from '@ant-design/icons-vue'
import { GetDateFromNow, GetShortDateFormat } from '@/services/helpers'
import { IAccessRole } from '@/types/interfaces/IAccessRole'
import { AppUserData } from '@/types/appContracts/AppUserData'
import { UserRoleData } from '@/types/appcontracts/UserRoleData'

export default defineComponent({
  name: 'UsersRoles',
  components: {
    SyncOutlined,
    CheckCircleTwoTone,
    ExclamationCircleTwoTone,
    UserOutlined,
  },
  setup() {
    const columns = ref([
      {
        title: '',
        dataIndex: 'displayPhoto',
        key: 'displayPhoto',
        width: '5%',
        slots: { customRender: 'photo' },
      },
      {
        title: 'Name',
        dataIndex: 'displayName',
        key: 'displayName',
        slots: { customRender: 'name' },
      },
      {
        title: 'Role',
        dataIndex: 'customClaims',
        key: 'role',
        slots: { customRender: 'role' },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        ellipsis: true,
        slots: { customRender: 'email' },
      },
      {
        title: 'Creation Time',
        dataIndex: 'creationTime',
        key: 'creationTime',
        ellipsis: true,
        slots: { customRender: 'customDateFormat' },
      },
      {
        title: 'Last Signin',
        dataIndex: 'lastSignInTime',
        key: 'lastSignInTime',
        ellipsis: true,
        slots: { customRender: 'customDateFormat' },
      },
      {
        title: 'Actions',
        key: 'actions',
        slots: { customRender: 'actions' },
      },
    ])
    const store = useStore()
    const isSetUserRoleModalVisible = ref(false)
    const isSetUserRoleModalLoading = ref(false)
    const currentRecord = ref<AppUserData | null>(null)
    const newRoleValue = ref('')
    const reloadIconType = ref(false)
    const loading = ref(false)
    const users = ref<AppUserData[]>([])
    const currUser = computed(() => store.getters['user/user'])
    const appRoleList = computed(
      () => store.getters['appGlobals/getAppRoles'] as IAccessRole[] | null,
    )
    const currRole = computed(() => appRoleList.value?.find((x) => x.role === currUser.value?.role))
    // const currLevel = computed(() => currRole.value?.level)
    const roleOptions = computed(() => {
      return appRoleList.value
        ?.filter((x) => x.level < (currRole.value?.level || 10))
        ?.map((x) => {
          return {
            value: x.role,
            label: x.role.toUpperCase(),
            key: x.role,
          }
        })
    })

    const getTableRowKey = (record: any) => {
      return record.uid
    }

    const getFilteredRole = (customClaims: any) => {
      if (!customClaims) return 'N/A'
      return customClaims.role.toUpperCase()
    }

    // const canEditLevel = (record: any) => {
    //   const role = record.customClaims.role
    //   const level = appRoleList.value?.find((x) => x.role === role)?.level || 10
    //   if (level < (currLevel.value || 10)) {
    //     return true
    //   }
    //   return false
    // }

    const handleSetUserRoleOk = async () => {
      isSetUserRoleModalLoading.value = true
      const payload: UserRoleData = {
        uid: currentRecord.value?.uid || null,
        newRole: newRoleValue.value,
      }
      await store.dispatch(`user/${actions.UsersAction.SET_APP_USER_ROLE}`, payload)
      isSetUserRoleModalVisible.value = false
      isSetUserRoleModalLoading.value = false
      newRoleValue.value = ''
      await loadAppUsers()
    }

    const handleSetUserRoleCancel = () => {
      isSetUserRoleModalVisible.value = false
      newRoleValue.value = ''
    }

    const showSetUserRoleModal = (record: any) => {
      currentRecord.value = record
      isSetUserRoleModalVisible.value = true
    }

    const showUserDeleteConfirm = () => {
      Modal.confirm({
        title: 'Are you sure you want to delete the user ?',
        content: 'This operation cannot be reverted back.',
        onOk: () => {
          // return deletePowerUsers(record).then((isSuccess) => {
          //   console.log(isSuccess)
          // })
          console.log('Delete feature coming soon.')
        },
        onCancel() {},
      })
    }

    const handleDataReload = async () => {
      await loadAppUsers()
    }

    onMounted(async () => {
      await loadAppUsers()
    })

    const loadAppUsers = async () => {
      reloadIconType.value = true
      loading.value = true
      users.value = await store.dispatch(`user/${actions.UsersAction.GET_APP_USERS}`)
      reloadIconType.value = false
      loading.value = false
    }

    const displayDateFromNow = (date: any, key: any) => {
      return key != 'creationTime'
        ? `${GetDateFromNow(date)} (${GetShortDateFormat(date)})`
        : GetShortDateFormat(date)
    }

    return {
      columns,
      isSetUserRoleModalVisible,
      isSetUserRoleModalLoading,
      currentRecord,
      newRoleValue,
      reloadIconType,
      getTableRowKey,
      getFilteredRole,
      handleSetUserRoleOk,
      handleSetUserRoleCancel,
      showSetUserRoleModal,
      showUserDeleteConfirm,
      handleDataReload,
      loading,
      users,
      displayDateFromNow,
      roleOptions,
    }
  },
})
