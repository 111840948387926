<template>
  <div>
    <div>
      <div class="card card-top card-top-primary">
        <div class="card-header card-header-flex">
          <div class="d-flex flex-column justify-content-center mr-auto">
            <h5 class="mb-0">
              <strong>Users And Roles</strong>
            </h5>
          </div>
          <div class="d-flex flex-column justify-content-center">
            <button type="button" class="btn btn-light" @click="handleDataReload">
              <SyncOutlined :spin="reloadIconType" />
            </button>
          </div>
          <!-- Add actions here if needed -->
        </div>
        <div class="card-body">
          <a-skeleton :loading="loading" active>
            <div class="table-responsive">
              <a-table
                :columns="columns"
                :data-source="users"
                :row-key="getTableRowKey"
                :scroll="{ x: 900 }"
              >
                <template #photo="{record}">
                  <div class="d-flex flex-wrap align-items-start">
                    <div class="flex-shrink-0 mr-4">
                      <a-avatar
                        shape="square"
                        size="small"
                        style="color: #595c97; background-color: #f2f4f8;"
                        :src="record.photoUrl"
                      >
                        <template #icon><UserOutlined /></template>
                      </a-avatar>
                    </div>
                  </div>
                </template>
                <template #name="{text}">
                  <span>{{ text }}</span>
                </template>
                <template #role="{text}">
                  <a-tag :color="text === null ? 'volcano' : 'cyan'">
                    {{ getFilteredRole(text) }}
                  </a-tag>
                </template>
                <template #email="{text, record}">
                  <CheckCircleTwoTone
                    two-tone-color="#52c41a"
                    v-if="record.emailVerified"
                    style="padding: 2px;"
                  />
                  <ExclamationCircleTwoTone v-else style="padding: 2px;" />
                  <span :title="text">{{ text }} </span>
                </template>
                <template #customDateFormat="{text, column: {key}}">
                  {{ displayDateFromNow(text, key) }}
                </template>
                <template #actions="{record}">
                  <div>
                    <a-tooltip placement="top">
                      <template #title>
                        <span>Set Role</span>
                      </template>
                      <a class="btn btn-sm btn-light mr-2" @click="showSetUserRoleModal(record)">
                        <i class="fe fe-sliders" />
                      </a>
                    </a-tooltip>
                    <a-tooltip placement="top">
                      <template #title>
                        <span>Disable Account</span>
                      </template>
                      <a class="btn btn-sm btn-light mr-2" disabled>
                        <i class="fe fe-user-minus" />
                      </a>
                    </a-tooltip>
                    <a-tooltip placement="top">
                      <template #title>
                        <span>Delete Account</span>
                      </template>
                      <a class="btn btn-sm btn-light mr-2" @click="showUserDeleteConfirm(record)">
                        <i class="fe fe-trash-2" />
                      </a>
                    </a-tooltip>
                  </div>
                </template>
              </a-table>
            </div>
          </a-skeleton>
        </div>
        <a-modal
          :title="`Set User Role for ${currentRecord?.displayName}`"
          :visible="isSetUserRoleModalVisible"
          :confirm-loading="isSetUserRoleModalLoading"
          @ok="handleSetUserRoleOk"
          @cancel="handleSetUserRoleCancel"
        >
          <p>Select a role</p>
          <div class="mb-4 width-300">
            <a-select
              placeholder="Select a Role"
              style="width: 100%;"
              allow-clear
              v-model:value="newRoleValue"
              :options="roleOptions"
            />
            <!-- <a-select style="width: 100%;" v-model:value="newRoleValue">
              <a-select-option value="admin">Admin</a-select-option>
              <a-select-option value="manager">Manager</a-select-option>
              <a-select-option value="employee">Employee</a-select-option>
              <a-select-option value="staff">Staff</a-select-option>
            </a-select> -->
          </div>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, defineComponent, computed } from 'vue'
import { Modal } from 'ant-design-vue'
import { useStore } from 'vuex'
import * as actions from '../../../store/actions.type'
import {
  SyncOutlined,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  UserOutlined,
} from '@ant-design/icons-vue'
import { GetDateFromNow, GetShortDateFormat } from '@/services/helpers'
import { IAccessRole } from '@/types/interfaces/IAccessRole'
import { AppUserData } from '@/types/appContracts/AppUserData'
import { UserRoleData } from '@/types/appcontracts/UserRoleData'

export default defineComponent({
  name: 'UsersRoles',
  components: {
    SyncOutlined,
    CheckCircleTwoTone,
    ExclamationCircleTwoTone,
    UserOutlined,
  },
  setup() {
    const columns = ref([
      {
        title: '',
        dataIndex: 'displayPhoto',
        key: 'displayPhoto',
        width: '5%',
        slots: { customRender: 'photo' },
      },
      {
        title: 'Name',
        dataIndex: 'displayName',
        key: 'displayName',
        slots: { customRender: 'name' },
      },
      {
        title: 'Role',
        dataIndex: 'customClaims',
        key: 'role',
        slots: { customRender: 'role' },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        ellipsis: true,
        slots: { customRender: 'email' },
      },
      {
        title: 'Creation Time',
        dataIndex: 'creationTime',
        key: 'creationTime',
        ellipsis: true,
        slots: { customRender: 'customDateFormat' },
      },
      {
        title: 'Last Signin',
        dataIndex: 'lastSignInTime',
        key: 'lastSignInTime',
        ellipsis: true,
        slots: { customRender: 'customDateFormat' },
      },
      {
        title: 'Actions',
        key: 'actions',
        slots: { customRender: 'actions' },
      },
    ])
    const store = useStore()
    const isSetUserRoleModalVisible = ref(false)
    const isSetUserRoleModalLoading = ref(false)
    const currentRecord = ref<AppUserData | null>(null)
    const newRoleValue = ref('')
    const reloadIconType = ref(false)
    const loading = ref(false)
    const users = ref<AppUserData[]>([])
    const currUser = computed(() => store.getters['user/user'])
    const appRoleList = computed(
      () => store.getters['appGlobals/getAppRoles'] as IAccessRole[] | null,
    )
    const currRole = computed(() => appRoleList.value?.find((x) => x.role === currUser.value?.role))
    // const currLevel = computed(() => currRole.value?.level)
    const roleOptions = computed(() => {
      return appRoleList.value
        ?.filter((x) => x.level < (currRole.value?.level || 10))
        ?.map((x) => {
          return {
            value: x.role,
            label: x.role.toUpperCase(),
            key: x.role,
          }
        })
    })

    const getTableRowKey = (record: any) => {
      return record.uid
    }

    const getFilteredRole = (customClaims: any) => {
      if (!customClaims) return 'N/A'
      return customClaims.role.toUpperCase()
    }

    // const canEditLevel = (record: any) => {
    //   const role = record.customClaims.role
    //   const level = appRoleList.value?.find((x) => x.role === role)?.level || 10
    //   if (level < (currLevel.value || 10)) {
    //     return true
    //   }
    //   return false
    // }

    const handleSetUserRoleOk = async () => {
      isSetUserRoleModalLoading.value = true
      const payload: UserRoleData = {
        uid: currentRecord.value?.uid || null,
        newRole: newRoleValue.value,
      }
      await store.dispatch(`user/${actions.UsersAction.SET_APP_USER_ROLE}`, payload)
      isSetUserRoleModalVisible.value = false
      isSetUserRoleModalLoading.value = false
      newRoleValue.value = ''
      await loadAppUsers()
    }

    const handleSetUserRoleCancel = () => {
      isSetUserRoleModalVisible.value = false
      newRoleValue.value = ''
    }

    const showSetUserRoleModal = (record: any) => {
      currentRecord.value = record
      isSetUserRoleModalVisible.value = true
    }

    const showUserDeleteConfirm = () => {
      Modal.confirm({
        title: 'Are you sure you want to delete the user ?',
        content: 'This operation cannot be reverted back.',
        onOk: () => {
          // return deletePowerUsers(record).then((isSuccess) => {
          //   console.log(isSuccess)
          // })
          console.log('Delete feature coming soon.')
        },
        onCancel() {},
      })
    }

    const handleDataReload = async () => {
      await loadAppUsers()
    }

    onMounted(async () => {
      await loadAppUsers()
    })

    const loadAppUsers = async () => {
      reloadIconType.value = true
      loading.value = true
      users.value = await store.dispatch(`user/${actions.UsersAction.GET_APP_USERS}`)
      reloadIconType.value = false
      loading.value = false
    }

    const displayDateFromNow = (date: any, key: any) => {
      return key != 'creationTime'
        ? `${GetDateFromNow(date)} (${GetShortDateFormat(date)})`
        : GetShortDateFormat(date)
    }

    return {
      columns,
      isSetUserRoleModalVisible,
      isSetUserRoleModalLoading,
      currentRecord,
      newRoleValue,
      reloadIconType,
      getTableRowKey,
      getFilteredRole,
      handleSetUserRoleOk,
      handleSetUserRoleCancel,
      showSetUserRoleModal,
      showUserDeleteConfirm,
      handleDataReload,
      loading,
      users,
      displayDateFromNow,
      roleOptions,
    }
  },
})
</script>
